@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
  font-family: 'source_serif_proregular';
  font-style: normal;
  font-weight: normal;
  src:
    url('/src/assets/fonts/sourceserifpro-regular-webfont.woff2')
      format('woff2'),
    url('/src/assets/fonts/sourceserifpro-regular-webfont.woff') format('woff');
}

$source_serif_pro: 'source_serif_proregular';
$inter: 'Inter', sans-serif;
$open_sans: 'Open Sans', sans-serif;
