@import 'base';

#privacy,
#terms {
  color: $mainText;
  font-family: $inter;

  h1 {
    color: $mainText;
    font-family: $source_serif_pro;
    font-size: 2.5rem;
    margin-bottom: 0.75rem;
  }

  h2 {
    color: $mainText;
    font-family: $source_serif_pro;
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  h3,
  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;
    margin-bottom: 0.75rem;
  }

  h4 {
    font-weight: normal;
  }

  a {
    color: $expandText;
    text-decoration: underline;
  }

  p {
    margin-bottom: 0.75rem;
  }

  ul {
    list-style: circle;
    margin-left: 1.5rem;
  }

  section {
    margin-bottom: 1.5rem;
  }

  table {
    border: 1px solid $mainText;
    max-width: 700px;

    th,
    td {
      border: 1px solid $mainText;
      font-size: 0.875rem;
      padding: 8px;
    }
  }
}
