@import '../base';
@import '../vars.scss';

.cluster_card {
  border-bottom: 1px solid $border;
  display: flex;
  flex-direction: column;

  &:last-child {
    border: none;
  }

  .display_info {
    align-items: flex-start;
    border-bottom: 1px solid $border;
    cursor: pointer;
    display: flex;
    font-family: $inter;
    font-size: 0.875rem;
    padding: 1.25rem 2.8125rem 1.125rem;
    position: relative;

    &.collapsed {
      border-bottom: none;
    }

    &:hover {
      background: $cardHoverBg;
      box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }

    div {
      display: flex;

      &.list {
        flex-direction: column;
        flex-wrap: wrap;

        span {
          flex: 1 0 100%;
        }
      }

      &.tooltip {
        display: block;

        .view_more {
          display: inline-block;
          width: auto;
        }
      }

      &.subject {
        flex: 1 0 $subjectWidth;
        font-family: $inter;
        font-size: 1rem;

        span {
          flex: 0 0 80%;
        }
      }
      &.topics {
        flex: 1 0 $topicWidth;
      }
      &.articles {
        flex: 1 0 $articleWidth;
      }
      &.videos {
        display: flex;
        flex: 1 0 $videoWidth;

        svg {
          max-width: 220px;
          width: 100%;
        }
      }

      #chart {
        width: 50%;
      }

      span {
        flex: 0 0 60%;
      }

      .view_more {
        color: $expandText;
        font-family: $open_sans;
        font-size: 14px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  .xAxis,
  .yAxis {
    text {
      font-family: $inter;
      font-size: 12px;
      font-weight: 500;
    }

    .tick line {
      display: none;
    }
  }
}
