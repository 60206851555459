@import '../base';

.video_tile {
  background: $white;
  border: 1px solid $videoTileBorder;
  display: flex;
  flex: 1 1 48%;
  max-width: 460px;
  min-width: 380px;
  padding: 0.75rem;

  img {
    margin-right: 0.688rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    justify-content: space-between;

    a {
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .video-title {
      font-family: $inter;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 0.375rem;
    }

    .video-summary {
      color: #767575;
      font-family: $inter;
      font-size: 0.75rem;
      margin-bottom: 0.375rem;
    }

    .row {
      align-items: center;
      color: #767575;
      column-gap: 0.5rem;
      display: flex;
      font-family: $inter;

      .added {
      }

      .view {
        align-items: center;
        display: flex;
      }

      .download {
        background-position: bottom 4px right 0px;
        background-repeat: no-repeat;
        font-family: $inter;
        font-weight: 500;
        height: 18px;
        padding-right: 1rem;
      }
    }
  }
}
