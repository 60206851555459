@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400,500,600,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,300,400,500,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400,500,600,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,300,400,500,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400,500,600,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,300,400,500,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400,500,600,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,300,400,500,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400,500,600,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,300,400,500,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
@font-face {
  font-family: source_serif_proregular;
  font-style: normal;
  font-weight: normal;
  src: url("sourceserifpro-regular-webfont.2e18cb92.woff2") format("woff2"), url("sourceserifpro-regular-webfont.b8413ae3.woff") format("woff");
}

body {
  font-size: 16px;
}

div[id^="more-topics-"] {
  color: #151515;
  z-index: 10;
  background-color: #fbfbfa;
  max-width: 9.375rem;
  max-height: 16rem;
  font-family: Inter, sans-serif;
  font-size: .875rem;
  transition: none;
  overflow: hidden auto;
  box-shadow: 0 2px 4px #00000040;
}

.zeitgeist_wrapper {
  font-family: source_serif_proregular;
}

.zeitgeist_wrapper .loading {
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  display: flex;
}

.zeitgeist_wrapper .top_header {
  margin-bottom: .625rem;
  font-size: 1.125rem;
}

.zeitgeist_wrapper .subheader {
  font-family: source_serif_proregular;
  font-size: 14px;
  line-height: 140%;
}

.zeitgeist_wrapper .filter_wrapper {
  justify-content: space-between;
  align-items: end;
  margin-top: 1.75rem;
  display: flex;
}

.zeitgeist_wrapper .filter_wrapper .selects {
  flex: 1 0 auto;
  column-gap: 1rem;
  display: flex;
}

.zeitgeist_wrapper .filter_wrapper .filters {
  flex: none;
  padding-right: 1rem;
  display: flex;
}

.zeitgeist_wrapper .filter_wrapper input, .zeitgeist_wrapper .filter_wrapper select {
  appearance: none;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: auto;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  min-width: 20%;
  height: 48px;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.zeitgeist_wrapper .filter_wrapper input {
  background-position: 1rem;
  flex: 0 30.8%;
  padding: .688rem 1rem .688rem 3rem;
  font-size: 1.125rem;
}

.zeitgeist_wrapper .filter_wrapper input:focus {
  outline: none;
}

.zeitgeist_wrapper .filter_wrapper input::placeholder {
  color: #5d5e6f;
}

.zeitgeist_wrapper .filter_wrapper select {
  background-position: right .5rem center;
  border-radius: 4px;
  flex: 0 20%;
  padding: .688rem 2rem .688rem 1rem;
  font-size: 1rem;
}

.zeitgeist_wrapper .filter_wrapper select.recency {
  min-width: 9.375rem;
}

.zeitgeist_wrapper .filter_wrapper select.topic {
  flex: 0 0 27.6%;
}

.zeitgeist_wrapper .results {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: .5rem;
  margin-top: 1.125rem;
}

.zeitgeist_wrapper .results .headers {
  color: #757575;
  text-transform: uppercase;
  border-bottom: 1px solid #dbdbdb;
  align-items: flex-start;
  padding: 1rem 2.8125rem 1.25rem;
  font: 600 14px Open Sans, sans-serif;
  display: flex;
}

.zeitgeist_wrapper .results .headers .info {
  cursor: pointer;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  display: block;
}

.zeitgeist_wrapper .results .headers div {
  align-items: center;
  column-gap: 5px;
  display: flex;
}

.zeitgeist_wrapper .results .headers div.subject {
  flex: 1 0 32%;
}

.zeitgeist_wrapper .results .headers div.topics {
  flex: 1 0 30%;
}

.zeitgeist_wrapper .results .headers div.articles {
  flex: 1 0 15%;
}

.zeitgeist_wrapper .results .headers div.videos {
  flex: 1 0 22%;
}

.zeitgeist_wrapper .results .headers div#tooltip {
  text-transform: none;
  max-width: 6.75rem;
}

.zeitgeist_wrapper .summary {
  font-family: source_serif_proregular;
}

.zeitgeist_wrapper .summary .narrative-section {
  margin-bottom: 1.5rem;
}

.zeitgeist_wrapper .summary .narrative-section .narrative-title {
  color: #757575;
  text-transform: uppercase;
  margin-bottom: .75rem;
  font: 600 14px Open Sans, sans-serif;
}

.zeitgeist_wrapper .summary .narrative-section .narrative-content {
  font-size: 1rem;
  line-height: 1.5;
}

.zeitgeist_wrapper .summary .narrative-section .narrative-content .show_more {
  cursor: pointer;
  margin-left: .5rem;
  font-family: Inter, sans-serif;
  font-size: .875rem;
}

.zeitgeist_wrapper .summary .narrative-section .narrative-content .show_more:hover {
  text-decoration: underline;
}

.zeitgeist_wrapper .summary .narrative-perspectives {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  display: grid;
}

@font-face {
  font-family: source_serif_proregular;
  font-style: normal;
  font-weight: normal;
  src: url("sourceserifpro-regular-webfont.2e18cb92.woff2") format("woff2"), url("sourceserifpro-regular-webfont.b8413ae3.woff") format("woff");
}

.video_tile {
  background: #fff;
  border: 1px solid #dfdddb;
  flex: 48%;
  min-width: 380px;
  max-width: 460px;
  padding: .75rem;
  display: flex;
}

.video_tile img {
  margin-right: .688rem;
}

.video_tile .content {
  flex-direction: column;
  justify-content: space-between;
  font-size: .75rem;
  display: flex;
}

.video_tile .content a {
  flex-direction: column;
  flex: 100%;
  justify-content: space-between;
  display: flex;
}

.video_tile .content .video-title {
  margin-bottom: .375rem;
  font-family: Inter, sans-serif;
  font-size: .875rem;
  line-height: 1.25rem;
}

.video_tile .content .video-summary {
  color: #767575;
  margin-bottom: .375rem;
  font-family: Inter, sans-serif;
  font-size: .75rem;
}

.video_tile .content .row {
  color: #767575;
  align-items: center;
  column-gap: .5rem;
  font-family: Inter, sans-serif;
  display: flex;
}

.video_tile .content .row .view {
  align-items: center;
  display: flex;
}

.video_tile .content .row .download {
  background-position: right 0 bottom 4px;
  background-repeat: no-repeat;
  height: 18px;
  padding-right: 1rem;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

@font-face {
  font-family: source_serif_proregular;
  font-style: normal;
  font-weight: normal;
  src: url("sourceserifpro-regular-webfont.2e18cb92.woff2") format("woff2"), url("sourceserifpro-regular-webfont.b8413ae3.woff") format("woff");
}

.cluster_detail {
  background: #fbfbfa;
  height: auto;
  position: relative;
}

.cluster_detail.collapsed {
  height: 0;
  overflow: hidden;
}

.cluster_detail .debug {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.cluster_detail .detail_content {
  padding: 1.25rem 1.5rem;
}

.cluster_detail .detail_content .subject {
  margin-bottom: .75rem;
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  line-height: 24px;
}

.cluster_detail .detail_content .summary {
  word-break: break-word;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.cluster_detail .detail_content .summary .narrative-section {
  margin-bottom: 1.25rem;
}

.cluster_detail .detail_content .summary .narrative-section .narrative-title {
  text-transform: uppercase;
  letter-spacing: .05em;
  color: #2a5246;
  border-bottom: 1px solid #d4d2cd;
  margin-bottom: .75rem;
  padding-bottom: .5rem;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.cluster_detail .detail_content .summary .narrative-section .narrative-content {
  word-break: break-word;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.cluster_detail .detail_content .summary .narrative-perspectives {
  gap: 2rem;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  display: flex;
}

.cluster_detail .detail_content .summary .narrative-perspectives .narrative-section {
  flex: 1;
}

.cluster_detail .show_more {
  color: #2a5246;
  cursor: pointer;
  margin-left: .313rem;
  font-size: .813rem;
  font-weight: 600;
  text-decoration: underline;
}

.cluster_detail .section {
  margin-bottom: 1.5rem;
}

.cluster_detail .section .section_content {
  width: 80%;
}

.cluster_detail .section_header {
  margin-bottom: .5rem;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.cluster_detail .topics_list, .cluster_detail .articles_list {
  flex-wrap: wrap;
  gap: .375rem;
  display: flex;
}

.cluster_detail .topics_list .topic_pill, .cluster_detail .topics_list .article_pill, .cluster_detail .articles_list .topic_pill, .cluster_detail .articles_list .article_pill {
  color: #5e5d5b;
  background-color: #f0ede9;
  border: 1px solid #d4d2cd;
  border-radius: .375rem;
  flex: none;
  padding: .25rem .5rem;
  font-family: Inter, sans-serif;
  font-size: .813rem;
  line-height: 120%;
}

.cluster_detail .topics_list .article_pill, .cluster_detail .articles_list .article_pill {
  color: #49454f;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f4f2ef;
  background-position: .25rem;
  background-repeat: no-repeat;
  max-width: 330px;
  padding-left: 1.75rem;
  overflow: hidden;
}

.cluster_detail .videos_list {
  flex-wrap: wrap;
  gap: 16px 2%;
  display: flex;
}

@font-face {
  font-family: source_serif_proregular;
  font-style: normal;
  font-weight: normal;
  src: url("sourceserifpro-regular-webfont.2e18cb92.woff2") format("woff2"), url("sourceserifpro-regular-webfont.b8413ae3.woff") format("woff");
}

.cluster_card {
  border-bottom: 1px solid #dbdbdb;
  flex-direction: column;
  display: flex;
}

.cluster_card:last-child {
  border: none;
}

.cluster_card .display_info {
  cursor: pointer;
  border-bottom: 1px solid #dbdbdb;
  align-items: flex-start;
  padding: 1.25rem 2.8125rem 1.125rem;
  font-family: Inter, sans-serif;
  font-size: .875rem;
  display: flex;
  position: relative;
}

.cluster_card .display_info.collapsed {
  border-bottom: none;
}

.cluster_card .display_info:hover {
  background: #f7f6f4;
  box-shadow: -1px 2px 5px #0003;
}

.cluster_card .display_info div {
  display: flex;
}

.cluster_card .display_info div.list {
  flex-flow: column wrap;
}

.cluster_card .display_info div.list span {
  flex: 1 0 100%;
}

.cluster_card .display_info div.tooltip {
  display: block;
}

.cluster_card .display_info div.tooltip .view_more {
  width: auto;
  display: inline-block;
}

.cluster_card .display_info div.subject {
  flex: 1 0 32%;
  font-family: Inter, sans-serif;
  font-size: 1rem;
}

.cluster_card .display_info div.subject span {
  flex: 0 0 80%;
}

.cluster_card .display_info div.topics {
  flex: 1 0 30%;
}

.cluster_card .display_info div.articles {
  flex: 1 0 15%;
}

.cluster_card .display_info div.videos {
  flex: 1 0 22%;
  display: flex;
}

.cluster_card .display_info div.videos svg {
  width: 100%;
  max-width: 220px;
}

.cluster_card .display_info div #chart {
  width: 50%;
}

.cluster_card .display_info div span {
  flex: 0 0 60%;
}

.cluster_card .display_info div .view_more {
  color: #2a5246;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

.cluster_card .xAxis text, .cluster_card .yAxis text {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.cluster_card .xAxis .tick line, .cluster_card .yAxis .tick line {
  display: none;
}

@font-face {
  font-family: source_serif_proregular;
  font-style: normal;
  font-weight: normal;
  src: url("sourceserifpro-regular-webfont.2e18cb92.woff2") format("woff2"), url("sourceserifpro-regular-webfont.b8413ae3.woff") format("woff");
}

#privacy, #terms {
  color: #2f2f2e;
  font-family: Inter, sans-serif;
}

#privacy h1, #terms h1 {
  color: #2f2f2e;
  margin-bottom: .75rem;
  font-family: source_serif_proregular;
  font-size: 2.5rem;
}

#privacy h2, #terms h2 {
  color: #2f2f2e;
  margin-bottom: .75rem;
  font-family: source_serif_proregular;
  font-size: 2rem;
}

#privacy h3, #privacy h4, #terms h3, #terms h4 {
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

#privacy h4, #terms h4 {
  font-weight: normal;
}

#privacy a, #terms a {
  color: #2a5246;
  text-decoration: underline;
}

#privacy p, #terms p {
  margin-bottom: .75rem;
}

#privacy ul, #terms ul {
  margin-left: 1.5rem;
  list-style: circle;
}

#privacy section, #terms section {
  margin-bottom: 1.5rem;
}

#privacy table, #terms table {
  border: 1px solid #2f2f2e;
  max-width: 700px;
}

#privacy table th, #privacy table td, #terms table th, #terms table td {
  border: 1px solid #2f2f2e;
  padding: 8px;
  font-size: .875rem;
}
/*# sourceMappingURL=index.9741e645.css.map */
