@import 'base';
@import 'vars.scss';

body {
  font-size: 16px;
}

div[id^='more-topics-'] {
  background-color: $tooltipBg;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: $tooltipText;
  font-family: $inter;
  font-size: 0.875rem;
  max-height: 16rem;
  max-width: 9.375rem;
  overflow-x: hidden;
  overflow-y: auto;
  transition: none;
  z-index: 10;
}

.zeitgeist_wrapper {
  font-family: $source_serif_pro;

  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
  }

  .top_header {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }

  .subheader {
    font-family: $source_serif_pro;
    font-size: 14px;
    line-height: 140%;
  }

  .filter_wrapper {
    align-items: end;
    display: flex;
    justify-content: space-between;
    margin-top: 1.75rem;

    .selects {
      column-gap: 1rem;
      display: flex;
      flex: 1 0 auto;
    }

    .filters {
      display: flex;
      flex: 0 0 auto;
      padding-right: 1rem;
    }

    input,
    select {
      appearance: none;
      background-color: $white;
      background-repeat: no-repeat;
      background-size: auto;
      border: 1px solid $border;
      border-radius: 4px;
      font-family: $inter;
      font-weight: 500;
      height: 48px;
      min-width: 20%;
    }

    input {
      background-position: center left 1rem;
      flex: 0 1 30.8%;
      font-size: 1.125rem;
      padding: 0.688rem 1rem 0.688rem 3rem;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $placeholder;
      }
    }

    select {
      background-position: center right 0.5rem;
      border-radius: 4px;
      flex: 0 1 20%;
      font-size: 1rem;
      padding: 0.688rem 2rem 0.688rem 1rem;

      &.recency {
        min-width: 9.375rem;
      }

      &.topic {
        flex: 0 0 27.6%;
      }
    }
  }

  .results {
    background: $white;
    border: 1px solid $border;
    border-radius: 0.5rem;
    margin-top: 1.125rem;

    .headers {
      align-items: flex-start;
      border-bottom: 1px solid $border;
      color: #757575;
      display: flex;
      font: 600 14px $open_sans;
      padding: 1rem 2.8125rem 1.25rem;
      text-transform: uppercase;

      .info {
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        height: 1rem;
        width: 1rem;
      }

      div {
        align-items: center;
        column-gap: 5px;
        display: flex;

        &.subject {
          flex: 1 0 $subjectWidth;
        }
        &.topics {
          flex: 1 0 $topicWidth;
        }
        &.articles {
          flex: 1 0 $articleWidth;
        }
        &.videos {
          flex: 1 0 $videoWidth;
        }

        &#tooltip {
          max-width: 6.75rem;
          text-transform: none;
        }
      }
    }
  }
  .summary {
    font-family: $source_serif_pro;

    .narrative-section {
      margin-bottom: 1.5rem;

      .narrative-title {
        color: #757575;
        font: 600 14px $open_sans;
        text-transform: uppercase;
        margin-bottom: 0.75rem;
      }

      .narrative-content {
        font-size: 1rem;
        line-height: 1.5;

        .show_more {
          cursor: pointer;
          font-family: $inter;
          font-size: 0.875rem;
          margin-left: 0.5rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .narrative-perspectives {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      //border-top: 1px solid $border;
    }
  }
}
