@import '../base';

.cluster_detail {
  background: $detailBg;
  height: auto;
  position: relative;

  &.collapsed {
    height: 0;
    overflow: hidden;
  }

  .debug {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  .detail_content {
    padding: 1.25rem 1.5rem;

    .subject {
      font-family: $inter;
      font-size: 1.125rem;
      line-height: 24px;
      margin-bottom: 0.75rem;
    }

    .summary {
      font-family: $inter;
      font-size: 14px;
      line-height: 20px;
      word-break: break-word;

      .narrative-section {
        margin-bottom: 1.25rem;
  
        .narrative-title {
          font-family: $inter;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: $expandText;
          margin-bottom: 0.75rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid $topicPillBorder;
        }
  
        .narrative-content {
          font-family: $inter;
          font-size: 14px;
          line-height: 20px;
          word-break: break-word;
        }
      }
  
      .narrative-perspectives {
        display: flex;
        gap: 2rem;
        margin-top: 1.25rem;
        padding-top: 1.25rem;
        //border-top: 1px solid $topicPillBorder;
  
        .narrative-section {
          flex: 1;
        }
      }
    }
  }

  .show_more {
    color: $expandText;
    cursor: pointer;
    font-size: 0.813rem;
    font-weight: 600;
    margin-left: 0.313rem;
    text-decoration: underline;
  }

  .section {
    margin-bottom: 1.5rem;

    .section_content {
      width: 80%;
    }
  }

  .section_header {
    font-family: $inter;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .topics_list,
  .articles_list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.375rem;

    .topic_pill,
    .article_pill {
      background-color: $topicPillBg;
      border: 1px solid $topicPillBorder;
      border-radius: 0.375rem;
      color: $topicPillColor;
      flex: 0 0 auto;
      font-family: $inter;
      font-size: 0.813rem;
      line-height: 120%;
      padding: 0.25rem 0.5rem;
    }

    .article_pill {
      background-color: $articlePillBg;
      background-position: center left 0.25rem;
      background-repeat: no-repeat;
      color: $articlePillColor;
      cursor: pointer;
      max-width: 330px;
      overflow: hidden;
      padding-left: 1.75rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .videos_list {
    column-gap: 2%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
